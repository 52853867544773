/** @jsx jsx */
import { jsx } from "theme-ui";
import { Link } from "gatsby";

const PageNotFound = () => {
  return (
    <div sx={{ textAlign: "center" }}>
      Page not found. Return{" "}
      <Link sx={{ color: "text" }} to="/">
        home
      </Link>
      .
    </div>
  );
};

export default PageNotFound;
